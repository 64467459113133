// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/i18n.ts"
);
import.meta.hot.lastModified = "1721206196274.3403";
}
// REMIX HMR END

export default {
  // This is the list of languages your application supports
  supportedLngs: ["fr", "en"],
  // This is the language you want to use in case
  // if the user language is not in the supportedLngs
  fallbackLng: "fr",
  // The default namespace of i18next is "translation", but you can customize it here
  defaultNS: "common",
  // Disabling suspense is recommended
  react: { useSuspense: false },
};
